@import '../../variables';

.CheckboxFieldCreator {
  margin-top: 50px;
  position: relative;
  display: flex;
  align-items: center;
}

.CheckboxFieldCreator__add {
  padding: 15px;
  height: 54px;
  width: 54px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1.5px solid $yellow;
  position: relative;
  background: $yellow;
  cursor: pointer;
  margin-bottom: 20px;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background: #fff;
    transform: translate(-50%, -50%);
  }

  span:last-child {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.CheckboxFieldCreator__input {
  display: inline-block !important;
  width: calc(50% - 15px - 54px);
}
