@import '../../variables';

.LogInModalSaveButton {
  border-radius: 31px;
  background: $yellow;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  color: #0F0F1E;
  font-size: 12px;
  padding: 15.5px 51px;
  outline: 0;
  margin: 80px auto;
  min-width: 156px;
  letter-spacing: 1px;
  display: block;
  cursor: pointer;

  &:disabled {
    opacity: .6;
    cursor: default;
  }
}
