@import '../../variables';

.ColumnParagraph {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  color: $grey;

  a {
    color: $azure;
    font-weight: 700;
  }
}
