@import '../../variables';

.StepNavigator {
  position: fixed;
  width: 100%;
  left: 100px;
  top: 100px;
  background: #fff;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  z-index: 1;
}
.StepNavigator__list {
  width: 90%;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 2.5%;
  flex-wrap: wrap;
  justify-content: center;
}
.StepNavigator__list--item {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color: #8492A1;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  min-width: 10%;
  min-height: 36px;
  background: none!important;
  border: none;
  padding: 0!important;

  &:hover, &.active {
    color: #000;
    font-weight: 600;
  }
  &.optional {
    color: #d2d2d2;
    pointer-events: none;
  }
  &.alert {
    color: $pink;
  }
}