.HeaderSearch {
  width: 200px;
  padding: 9.5px 15px;
  margin-right: 40px;
  background: #F2F3F7;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  outline: 0;
}
