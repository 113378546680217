@import '../../variables';

.LocaleSelectorLogin {
  display: flex;
  align-items: baseline;
  position: absolute;
  bottom: 50px;
  left: 50px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    line-height: 19.5px;
    font-size: 13px;
  }

  li {
    cursor: pointer;
    color: #8492A1;
    padding: 0 5px;

    &:hover {
      color: #fff;
    }
  }

  .current {
    color: $yellow;
  }

  button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: inherit;
    cursor: pointer;
  }
}
