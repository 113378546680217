.ArchiveHeader {
  .actions {
    display: flex;
    align-items: center;
  }

  .TabsWrapper {
    .tabs__wrapper {
      margin-bottom: unset;
    }
  }
}
