.FormControl {
  position: fixed;
  background-color: white;
  height: 100px;
  left: 100px;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #E6E9ED;
  display: flex;
  align-items: center;
  z-index: 1;

  &.Scrollable {
    transition: .5s;
    -webkit-box-shadow: -40px -30px 40px -20px rgba(118, 140, 158, 0.3);
    -moz-box-shadow: -40px -30px 40px -20px rgba(118, 140, 158, 0.3);
    box-shadow: -40px -30px 40px -20px rgba(118, 140, 158, 0.3);
  }
}

.FormControl__previous {
  position: absolute;
  left: 260px;
  top: calc(50% - 31px);
}

.FormControl__next {
  position: absolute;
  right: 370px;
  top: calc(50% - 31px);
}

.FormControl__finalize {
  position: absolute;
  right: 160px;
  top: calc(50% - 31px);
}

.FormControl__cancel {
  position: absolute;
  left: 60px;
  top: calc(50% - 31px);
}
