.Header {
  z-index: 1;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: white;
  padding: 0 calc(350px + 60px) 0 60px;
  border-bottom: 1px solid #E6E6E6;
  height: 101px;

  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  align-items: center;
}

.Header--expanded {
  padding: 2.5%;
  width: calc(100% - 100px);
}
