.TodoListItem {
  padding: 25px 0 25px 60px;
  border-bottom: 1px solid #E6E6E6;
  position: relative;
}

.TodoListItem__title {
  color: #0F0F1E;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: 5px 0 0 0;
  max-width: 300px;
}

.TodoListItem__user {
  color: #8492A1;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}


.TodoListItem__checkbox {
  position: absolute;
  left: 0;
}

.TodoListItem__delete {
  position: absolute;
  right: 0;
  padding: 6px 25px;
  border: 1px solid #B1B2BD;
  border-radius: 31px;
  color: #B1B2BD;
  font-size: 12px;
  font-weight: 400;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: auto;
  display: inline-block;
}

.TodoListItem__content-toggle {
  position: absolute;
  right: 0;
  top: 35px;
  font-size: 22px;
  color: #8492A1;
  cursor: pointer;
}

.TodoListItem__content {
  margin-top: 10px;
}
