.Loader {
  display: flex;
  visibility: hidden;
  animation: 0s linear 0.3s forwards Loader;
}

@keyframes Loader {
  to {
    visibility: visible;
  }
}
