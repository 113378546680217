@import '../../variables';

.PartnerLogo {
  position: relative;
  right: 0;
  margin-left: 40px;
}

.PartnerLogo__image {
  max-width: 52px;
  max-height: 52px;
}

.PartnerLogo__text {
  color: $grey;
  font-size: 13px;
  line-height: 13px;
  margin: 7px 0 0 0;
  text-align: center;
}
