@import '../../variables';

.TimelineItem {
  padding: 30px 0 30px 60px;
  position: relative;
  margin: 0;
}

.TimelineItem__line {
  position: absolute;
  left: 10px;
  height: 100%;
  background: #D9DADE;
  width: 2px;
  bottom: 0;
}

.TimelineItem__bullet {
  position: absolute;
  top: calc(50% - 11px);
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #D9DADE;
}

.TimelineItem__bullet--current {
  border-color: $yellow;

  &::before {
    content: '';
    display: block;
    margin: 3px;
    width: 12px;
    height: 12px;
    background: $yellow;
    border-radius: 50%;
  }
}

.TimelineItem__bullet--past {
  background: #D9DADE;

  > i {
    display: block;
    width: 100%;
    line-height: 19px;
    text-align: center;
    color: #fff;
    font-size: 13px;
  }
}

.TimelineItem__date {
  color: #0F0F1E;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.TimelineItem__content {
  color: #6F7080;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
}

.TimelineItem__content--overdue {
  color: $pink;
}

.TimelineItem:first-child {
  padding: 0 0 30px 60px;

  > .TimelineItem__bullet {
    top: calc(50% - 11px - 15px);
  }

  > .TimelineItem__line {
    height: calc(50% + 15px);
  }
}

.TimelineItem:last-child {
  .TimelineItem__line {
    height: 40%;
    top: 0;
  }
}
