@import '../../variables';

.LogInModalBackLink {
  display: block;
  font-size: 13px;
  color: $grey;
  margin: 0;
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.LogInModalBackLink:hover {
  color: $light-grey;
}
