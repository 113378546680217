.dropdown-menu {
  display: block;
  left: auto;
  right: 0;
  position: absolute;
  top: 100%;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 8px 0;
  padding: 0.5rem 0;
  margin: 2px 0 0;
  margin: 0.125rem 0 0;
  font-size: 16px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 4px 24px;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.popper {
  z-index: 1000;
}

.ArchiveTableDropdown__button {
  cursor: pointer;
  display: flex;
}
.ArchiveTableDropdown__button:hover, .ArchiveTableDropdown__button:focus {
  opacity: 0.7;
}
