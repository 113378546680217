@import '../../variables';

.PasswordForgotten {
  .Modal {
    background: #F2F3F7;
  }

  .form__input-wrapper  {
    margin-bottom: 20px;

    input {
      margin-bottom: 0;
    }

    span {
      font-size: 12px;
      color: $pink;
      font-weight: 400;
    }
  }
  p {
    font-size: 14px;
    color: #6F7080;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
  }
}
