@import '../../variables';

.Button {
  border: 2px solid #8492a1;
  padding: 1.667em 4.167em;
  text-transform: uppercase;
  color: #6f7080;
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 2.9em;
  cursor: pointer;
  background: #fff;

  outline: 0;
  cursor: pointer;

  &:disabled {
    background-color: $grey;
    cursor: default;
  }
}

.Button--md {
  font-size: 0.75em;
}
.Button--sm-flex {
  display: flex;
  margin: 1em 0;
  padding: 0.3em 2em;
}

.Button--primary {
  background: $yellow;
  border-color: $yellow;
  color: #0f0f1e;
}

.Button--secondary {
  border-color: $yellow;
  color: #0f0f1e;
}

.Button--dark {
  background: $dark_blue;
  border-color: #6f7080;
  color: #6f7080;
}
