@import '../../variables';

.ArchiveCreateButton {
  cursor: pointer;
  z-index: 1;

  > button {
    background: $yellow;
    border-radius: 35px;
    padding: 16px 40px;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    z-index: 1;
    border: 0;
  }
}
