@import '../../variables';

.LogInModal {
  width: 100%;
  height: 100vh;
  background: $dark_blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogInModal__inner {
  background: $blue;
  border-radius: 30px;
  box-shadow: 0 30px 130px 0 rgba(0,0,0,0.5);
  width: 470px;
  min-height: 670px;
  padding: 90px 50px 50px 50px;
  position: relative;

  > p {
    color: $grey;
    margin-bottom: 3rem;
  }
}

.LogInModel__logo {
  width: 46px;
  margin: 0 auto 85px auto;
  display: block;
}
