@import '../../variables';

.RiskAssessmentsShow__graph {
  background: linear-gradient(210.65deg, #EF2954 0%, #F4E25B 100%), linear-gradient(38.42deg, #1BC5B1 0%, #F4E25B 100%);
  box-shadow: 0 30px 130px 0 rgba(166,201,211,0.5), 0 6px 20px 0 rgba(15,15,30,0.14);
  border-radius: 30px;
  height: 208px;
  margin-bottom: 80px;
  position: relative;
  max-width: 680px;
  margin: 0 auto 50px auto;

  &.extended {
    margin-bottom: 150px;
  }

  .x_axis {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px!important;
    color: #8492A1;
    bottom: -50px;
  }

  .y_axis {
    position: absolute;
    left: -55px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    font-size: 13px!important;
    color: #8492A1;
  }

  .row {
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .col-md-3 {
    height: 52px;
    position: relative;
    border: 1px solid rgba(255,255,255,.3);
    border-collapse: collapse;
    text-align: center;
    padding: 0 0 0 15px;
    box-sizing: border-box;
    overflow: hidden;
    flex: 0 0 25%;

    .dot {
      position: relative;
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-top: 4px;
      margin-left: -15px;

      .outer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 40px;
        width: 40px;
        opacity: 0.22;
        border-radius: 50%;
        background: #fff;
        z-index: 0;
      }

      .inner {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
      }

      span {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: radial-gradient(circle, #40CBBA 0%, #2C796D 100%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
    }

    .draft_dot {
      span {
        opacity: 0;
      }
    }
  }
}
