@import '../../variables';

.OrganizationsExports__table {
  th, td {
    width: auto;

    &:first-child {
      width: 1em;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.OrganizationsExports__date {
  color: $azure !important;
}

.OrganizationsExports__result-name {
  text-decoration: underline;
  color: $grey;
}

.OrganizationsExports__delete {
  background: transparent;
  padding: 6px 25px;
  border: 1px solid #B1B2BD;
  border-radius: 31px;
  color: #B1B2BD;
  font-size: $font-xs;
  font-weight: 400;
  cursor: pointer;
  width: auto;
  display: inline-block;
}

.OrganizationsExports__head-content {
  margin-bottom: 50px;
}
