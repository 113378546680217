@import '../../variables';

.DatePicker {
  width: 450px;
  outline: 0;
  border: 1px solid #E6E9ED;
  padding: 15px;
  font-size: $font-md;
  line-height: 22px;
  font-weight: 400;
  background: none;
}
