@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 200;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Thin.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Thin.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 300;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Light.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Light.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Light.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 400;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Regular.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Regular.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Regular.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 500;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Semibold.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Semibold.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Semibold.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 700;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Bold.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Bold.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Bold.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 800;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Extrabld.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Extrabld.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Extrabld.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Extrabld.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaT';
  font-weight: 900;
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Black.eot');
  src: url('./fonts/ProximaNovaT/ProximaNovaT-Black.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaT/ProximaNovaT-Black.woff') format('woff'),url('./fonts/ProximaNovaT/ProximaNovaT-Black.ttf') format('truetype');
}
