.HeaderTimestamp {
  display: inline-block;

  font-size: 13px;
  color: #8492A1;
  font-weight: 400;

  text-align: right;
  flex: 1 0 33.3%;

  .HeaderTimestamp__date {
    font-style: italic;
  }
}
