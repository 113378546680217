@import '../../variables';

.FieldDisclaimer {
  font-weight: 400;
  color: #6F7080;
  margin: 25px 0 0 0;
  line-height: 26px;
  width: calc(50% - 15px);

  span {
    font-weight: 700;
  }

  a {
    color: $azure;
    font-weight: 700;
  }
}
