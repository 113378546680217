.SettingsButton {
  background: transparent;
  padding: 8.5px 25px;
  border: 1px solid #B1B2BD;
  border-radius: 31px;
  color: #B1B2BD;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  width: auto;
  display: inline-block;

  i {
    padding-right: 10px;
  }
}
