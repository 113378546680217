@import '../../variables';

.LogIn {
  width: 100%;
  height: 100vh;
  background: $dark_blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogIn__password {
  margin-top: 55px;
}
