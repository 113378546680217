@import '../../variables';

.Field {
  border: none;
  border: 1px solid #DDE0E3;
  font-size: $font-md;
  outline: 0;
  width: calc(50% - 15px);
  display: block;
  transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  resize: none;
  padding: 15px;
  height: 54px;
  margin-bottom: 20px;

  &:focus {
    border-color: $yellow;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #8492A1;
    font-weight: 300;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #8492A1;
    font-weight: 300;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #8492A1;
    font-weight: 300;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #8492A1;
    font-weight: 300;
  }

  &:disabled {
    background-color: transparent;
  }
}

.Field--fill {
  width: 100%;
}

.Field--textarea {
  height: 150px;
}

.Field--textarea:focus {

  border-color: $yellow;
}

.Field--error {
  border-color: $pink;
  border: 2px solid $pink;
}

.Field--textarea-wide {
  width: 100%;
}
