@import '../../variables';

.Label {
  color: #fff;
  background: #B1B2BD;
  border-radius: 1.45em;
  padding: .38em 1em .35em;
  font-size: .8125em;
  line-height: 1;
  display: inline-block;
  font-weight: 400;
  vertical-align: text-bottom;

  &:not(:first-child) {
    margin-left: 8px;
  }

  &.Label--default {
    border: none;
    background: #F2F3F7;
    color: #6F7080;
    margin-left: 0;
  }
}

.Label--alert {
  border: none;
  background: $pink;
}

.Label--draft {
  background: transparent;
  font-style: italic;
  color: #B1B2BD;
  font-size: .78em;
  font-weight: 500;
}
