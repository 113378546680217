@import '../../variables';

.OrganizationsDropdown {
  cursor: pointer;

  h1 {
    height: 24px;
    display: flex;
    align-items: center;
  }
  .dropdown-menu {
    left: 0;
    margin-top: 25px;
    min-width: 190px;

    .active {
      background-color: $light-grey;
    }
    .dropdown-item {
      white-space: initial;
    }
  }

  .arrow {
    margin-left: 10px;
  }
}
