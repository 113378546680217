@import '../../variables';

.TodoListItemNew {
  display: block;
  width: 100%;
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid #E6E6E6;
  cursor: pointer;

  > h5 {
    color: #B1B2BD;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    position: absolute;
    left: 60px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.TodoListItemNew__plus {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 1.5px solid $yellow;
  position: relative;
  background: $yellow;
  cursor: pointer;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background: #fff;
    transform: translate(-50%, -50%);
  }

  > span:last-child {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
