@import '../../variables';

.ListHeaderTab {
  color: #B1B2BD;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  float: right;
  display: inline-block;
  margin: 10px 0 0 30px;
  cursor: pointer;
}

.ListHeaderTab--active {
  color: $dark_blue;
}
