@import '../../variables.scss';

.FlashMessage {
  background: #151525;
  border-radius: 11px;
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 30px 50px;
  z-index: 999999;
  display: flex;
  align-items: center;

  img {
    display: inline-block;
    width: 20px;
    margin: 0 40px 0 0;
    height: auto;
  }

  h6 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    display: inline-block;
    max-width: 400px;


    span {
      font-weight: 400;
      color: $grey;
    }
  }
}
