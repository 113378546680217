.TableContainer {

  .table__wrapper {
    max-height: 500px;
    min-height: 155px;
    overflow: auto;
    margin-bottom: 60px;
  }

  h3 {
    display: inline-flex;
  }

  table {
    width: 100%;
    max-height: 500px;
    min-height: 150px;
    overflow: auto;
    position: relative;
    margin-bottom: 60px;
  }

  th {
    padding: 22px 15px;
    font-weight: 400;
  }

  tr {
    border-bottom: 1px solid #F2F3F7;

    &:hover {
      background-color: #F2F3F7;
    }

    &:nth-last-child(2), &:last-child {
      .panel {
        bottom: 25px;
      }
    }
  }

  td {
    width: 20%;
    padding: 22px 15px;
    font-size: 14px;
    font-weight: 400;
    color: #8492A1;

    &:first-child {
      font-size: 15px;
      font-weight: 600;
      color: #0F0F1E;
    }
  }
}
