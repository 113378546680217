.IntervalField > .IntervalField__input {
  display: inline-block;
  width: 100px;
}

.IntervalField__unit {
  margin: 11px 0 0 20px;
  display: inline-block;
  font-size: 22px;
}
