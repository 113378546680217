@import '../../variables';

.DataMappingDiagram {
  width: 1200px;
  margin: 0 auto 70px auto;

  @include max-screen(1350px) {
    width: 900px;
  }

  @include max-screen(1000px) {
    width: 720px;
  }

  @include max-screen(820px) {
    width: 600px;
  }

  @include max-screen(767px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @include max-screen(580px) {
    display: none;
  }

  .warning {
    color: $yellow;
    font-size: $font-xs;
    margin-left: 6px;
    cursor: pointer;
  }

  .flow_headlines {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div {
      flex: 1;
    }

    h2 {
      color: #0F0F1E;
      font-weight: 700;
      font-size: 22px;
      text-align: center;
    }
  }

  .flow {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .kilde, .virksomhed, .modtager {
      flex: 1;
      position: relative;
    }

    .connect_kilder, .connect_modtager {
      width: 1px;
      background: #8492A1;
      position: absolute;
      left: 32%;
      transform: translateX(-30%);

      @include max-screen(820px) {
        left: 31.4%;
      }

      span.arrow {
        position: absolute;
        right: -4px;
        top: -5.5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5.5px 0 5.5px 8px;
        border-color: transparent transparent transparent #8492A1;
      }

      span.arrow.reverse {
        right: auto;
        left: -4px;
        border-color: transparent #8492A1 transparent transparent;
        border-width: 5.5px 8px 5.5px 0;
      }

      span.dot{
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #8492A1;
        position: absolute;
        left: 0;
        top: -4.5px;
      }

      .virksomhed_ingoing, .virksomhed_outgoing {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -75px;
        width: 75px;
        height: 1px;
        background: #8492A1;

        @include max-screen(1350px) {
          width: 22px;
          right: -22px;
        }

        @include max-screen(820px) {
          width: 7px;
          right: -7px;
        }
      }

      .virksomhed_outgoing {
        right: auto;
        left: -75px;
        width: 75px;

        @include max-screen(1350px) {
          width: 25px;
          left: -25px;
        }

        @include max-screen(820px) {
          width: 10px;
          left: -10px;
        }
      }
    }

    .connect_modtager {
      left: 68%;

      @include max-screen(820px) {
        left: 68.6%;
      }
    }

    .link {
      position: absolute;
      left: 70.3%;
      top: 50%;
      transform: translate(-70%, -50%);
      width: 150px;
      height: 1px;
      background: #8492A1;

      @include max-screen(1350px) {
        left: 69.7%;
        width: 60px;
      }

      span.dot{
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #8492A1;
        position: absolute;
        left: 0;
        top: -4.5px;
      }

      span.arrow {
        position: absolute;
        right: -4px;
        top: -5.5px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5.5px 0 5.5px 8px;
        border-color: transparent transparent transparent #8492A1;
      }
    }

    .entry {
      right: auto;
      left: 30%;
      transform: translate(-33%, -50%);
    }

    .kilde {
      .diagram_item {
        .link {
          left: auto;
          right: -117px;
          width: 75px;

          @include max-screen(1350px) {
            right: -55px;
            width: 25px;

            .dot {
              left: -5px;
            }
          }

          @include max-screen(1000px) {
            width: 19px;
            right: -49px;
          }

          @include max-screen(820px) {
            width: 10px;
            right: -25px;
          }
        }
      }
    }

    .modtager {
      .diagram_item {
        .link {
        left: -68px;
        width: 75px;

          @include max-screen(1350px) {
            left: -38px;
            width: 25px;
          }

          @include max-screen(1000px) {
            width: 19px;
          }

          @include max-screen(820px) {
            width: 10px;
            left: -18px;
          }
        }
      }
    }
  }

  .diagram_item {
    text-align: left;
    width: 280px;
    margin: 30px auto;
    padding: 24px;
    display: block;
    border-radius: 20px;
    background-color: #F2F3F7;
    display: flex;

    .item_data {
      width: 100%;
    }

    .item_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .collapsible_data {
      margin-top: 35px;
    }
    .fa {
      cursor: pointer;
    }
    @include max-screen(1000px) {
      width: 136px;
    }

    h4 {
      margin-bottom: 0;
    }

    .transfer_basis {
      margin-top: 12px;
    }

    h6 {
      text-transform: uppercase;
      color: #8492A1;
      margin: 0;
    }

    p {
      color: #8492A1;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;

      @include max-screen(1000px) {
        display: none
      }
    }
  }
}
