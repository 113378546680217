@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 200;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Thin.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Thin.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Thin.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 300;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Light.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Light.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Light.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 400;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Regular.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Regular.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Regular.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 500;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Semibold.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Semibold.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Semibold.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 700;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Bold.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Bold.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Bold.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 800;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Extrabld.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Extrabld.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Extrabld.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Extrabld.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaS';
  font-weight: 900;
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Black.eot');
  src: url('./fonts/ProximaNovaS/ProximaNovaS-Black.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaS/ProximaNovaS-Black.woff') format('woff'),url('./fonts/ProximaNovaS/ProximaNovaS-Black.ttf') format('truetype');
}
