@import '../../variables';

.Sidebar {
  width: 350px;
  background: $dark_blue;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: width .6s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  overflow: hidden;
  overflow-y: auto;
  z-index: 2;
  padding: 30px 0;

  @include max-screen(767px) {
    display: none;
  }

  .logo {
    margin-left: 35px;
    img {
      width: 30px;
    }
  }

  @media screen and (min-height: 900px) {

    .log-out {
      position: absolute;
      bottom: 1rem;
      margin: 0;
      a {
        color: #6F7080;
      }
    }
  }

  .log-out {
    margin-top: 20px;
    padding: 0;
  }

  .general {
    margin-top: 30px;
    padding: 0;
  }

  ul {
    margin-bottom: 0;
    list-style: none;

    li {
      img {
        width: 32px;
        position: absolute;
        left: 35px;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .8s ease;
      }

      img.active_img {
        opacity: 0;
      }

      a {
        padding: 20px 0 20px 88px;
        color: #6F7080;
        font-size: 18px;
        display: block;
        height: 64px;
        text-overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        position: relative;

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 100%;
          border-left: 3px solid transparent;
          z-index: -1;
          transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
        }
      }

      .router-link-active {
        color: #fff;

        img.inactive_img {
          opacity: 0;
        }

        img.active_img {
          opacity: 1;
        }

        .bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          background: $blue;
          border-color: $yellow;
        }
      }
    }
  }

  &.collapsed {
    width: 100px;

    ul {
      li {
        a {
          span {
            display: none;
          }
        }
      }
    }
  }
}
