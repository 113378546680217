@import '../../variables';

.FileUpload {
  border: 2px dashed #E6E6E6;
  border-radius: 5px;
  padding: 40px 0;

  input[type="file"] {
    display: none;
   }

  &__instruction-label {
    cursor: pointer;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    margin: 0;
    font-weight: 700;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      padding: 0.5rem;
      text-align: center;

      &__close {
        font-size: 1.5rem;
        background-color: transparent;
        border: 0;
        line-height: 1;
        -webkit-appearance: none;
        cursor: pointer;
        padding: 0;
        position: relative;
        top: 3px;
      }

      &__link {
        color: $light-blue;
      }
    }
  }
}
