.OrganizationsShow__page {
  width: calc(100% - 350px);
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}

.OrganizationsShow__page--expanded {
  width: calc(100% - 100px);
}
