// animate_in_up ---------------------------- //

@-webkit-keyframes animate_in_up {
  0%   { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; }
}
@-moz-keyframes animate_in_up {
  0%   { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; }
}
@-o-keyframes animate_in_up {
  0%   { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; }
}
@keyframes animate_in_up {
  0%   { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; }
}

.fade_in_up {
  -webkit-animation: animate_in_up .8s forwards; /* Safari 4+ */
  -moz-animation:    animate_in_up .8s forwards; /* Fx 5+ */
  -o-animation:      animate_in_up .8s forwards; /* Opera 12+ */
  animation:         animate_in_up .8s forwards; /* IE 10+, Fx 29+ */
}

// animate_in_down ---------------------------- //

@-webkit-keyframes animate_in_down {
  0%   { opacity: 0; transform: translateY(-30px); }
  100% { opacity: 1; }
}
@-moz-keyframes animate_in_down {
  0%   { opacity: 0; transform: translateY(-30px); }
  100% { opacity: 1; }
}
@-o-keyframes animate_in_down {
  0%   { opacity: 0; transform: translateY(-30px); }
  100% { opacity: 1; }
}
@keyframes animate_in_down {
  0%   { opacity: 0; transform: translateY(-30px); }
  100% { opacity: 1; }
}

.fade_in_down {
  -webkit-animation: animate_in_down .8s forwards; /* Safari 4+ */
  -moz-animation:    animate_in_down .8s forwards; /* Fx 5+ */
  -o-animation:      animate_in_down .8s forwards; /* Opera 12+ */
  animation:         animate_in_down .8s forwards; /* IE 10+, Fx 29+ */
}

// animate_in_left ---------------------------- //

@-webkit-keyframes animate_in_left {
  0%   { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; }
}
@-moz-keyframes animate_in_left {
  0%   { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; }
}
@-o-keyframes animate_in_left {
  0%   { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; }
}
@keyframes animate_in_left {
  0%   { opacity: 0; transform: translateX(-30px); }
  100% { opacity: 1; }
}

.fade_in_left {
  -webkit-animation: animate_in_left .8s forwards; /* Safari 4+ */
  -moz-animation:    animate_in_left .8s forwards; /* Fx 5+ */
  -o-animation:      animate_in_left .8s forwards; /* Opera 12+ */
  animation:         animate_in_left .8s forwards; /* IE 10+, Fx 29+ */
}

// animate_in_right ---------------------------- //

@-webkit-keyframes animate_in_right {
  0%   { opacity: 0; transform: translateX(30px); }
  100% { opacity: 1; }
}
@-moz-keyframes animate_in_right {
  0%   { opacity: 0; transform: translateX(30px); }
  100% { opacity: 1; }
}
@-o-keyframes animate_in_right {
  0%   { opacity: 0; transform: translateX(30px); }
  100% { opacity: 1; }
}
@keyframes animate_in_right {
  0%   { opacity: 0; transform: translateX(30px); }
  100% { opacity: 1; }
}

.fade_in_right {
  -webkit-animation: animate_in_right .8s forwards; /* Safari 4+ */
  -moz-animation:    animate_in_right .8s forwards; /* Fx 5+ */
  -o-animation:      animate_in_right .8s forwards; /* Opera 12+ */
  animation:         animate_in_right .8s forwards; /* IE 10+, Fx 29+ */
}

// animate_out_up ---------------------------- //

@-webkit-keyframes animate_out_up {
  0% { opacity: 1; }
  100%   { opacity: 0; transform: translateY(-30px); }
}
@-moz-keyframes animate_out_up {
  0% { opacity: 1; }
  100%   { opacity: 0; transform: translateY(-30px); }
}
@-o-keyframes animate_out_up {
  0% { opacity: 1; }
  100%   { opacity: 0; transform: translateY(-30px); }
}
@keyframes animate_out_up {
  0% { opacity: 1; }
  100%   { opacity: 0; transform: translateY(-30px); }
}

.fade_out_up {
  -webkit-animation: animate_out_up .8s forwards; /* Safari 4+ */
  -moz-animation:    animate_out_up .8s forwards; /* Fx 5+ */
  -o-animation:      animate_out_up .8s forwards; /* Opera 12+ */
  animation:         animate_out_up .8s forwards; /* IE 10+, Fx 29+ */
}
