@import '../../variables';

.Checkbox {
  > input[type="checkbox"] {
    visibility: hidden;
    height: 0;
    float: left;
  }

  > span {
    background: none;
    border: 1.5px solid #8492A1;
    width: 16px;
    height: 16px;
    display: inline-block;
    float: left;
    border-radius: 5px;
    margin: 6px 10px 0 0;
    position: relative;
    width: 32px;
    height: 32px;

    i {
      font-size: 11px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #fff;
      opacity: 0;
      font-size: 14px;
    }
  }
}

.Checkbox--checked {
  > span {
    background: $yellow;
    border-color: $yellow;

    i {
      opacity: 1;
    }
  }
}
