@import '../../variables';

.FileListItem {
  margin-bottom: 5px;
}

.FileListItem__close {
  font-size: 1.5rem;
  background-color: transparent;
  border: 0;
  line-height: 1;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  top: 3px;
}

.FileListItem__name {
  text-decoration: underline;
  color: $grey;
}
