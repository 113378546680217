@import '../../variables';

.ArchiveActionSection {
  padding-bottom: 20px;
  margin-bottom: 70px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e6e6e6;

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  .Button {
    margin-left: 1em;
  }
}
