@import '../../variables';

.subscribtion-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: $dark_blue;
  justify-content: center;
  align-items: center;
  padding: 100px 0;

  &-content {
    width: 525px;
    text-align: center;

    h1 {
      color: #fff;
      margin: 80px 0 30px 0;
      font-size: 36px;
    }

    p {
      color: #6F7080;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 60px;

      span {
        color: #fff;
      }
    }

    .Button {
      display: block;
      margin: 10px auto;
      padding: 1.167em 4.167em;

      &--primary {
        color: $dark_blue;
      }

      &:disabled {
        color: rgba(255,255,255,.4);
        border-color: $grey;
      }
    }
  }

  &--accepted {
    background: #fff;

    &-content {
      width: 450px;

      h1 {
        color: $dark_blue;
      }

      .form {
        margin: 65px 0 0 0;

        .form__input-wrapper {
          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #E6E9ED;
            padding: 10px 0;
            margin:  0 0 30px 0;
          }

          label {
            float: left;
            font-size: 11px;
            color: #6F7080;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 0;
          }
        }

        .termsLabel {
          text-decoration: underline;
        }

        .Button {
          margin: 65px auto 0 auto;
        }
      }
    }
  }
}
