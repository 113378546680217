@import '../../variables';

.DataProcessingAgreementItem {
  margin: 30px 0 0;
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.DataProcessingAgreementItem__link {
  flex: 1 1 auto;
}

.DataProcessingAgreementItem__title {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0;
}

.DataProcessingAgreementItem__timestamp,
.DataProcessingAgreementItem__systems {
  color: #6F7080;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0;

  > em {
    font-style: normal;
    color: $azure;
  }
}

.DataProcessingAgreementItem__systems {
  margin-top: 4px;
}

.DataProcessingAgreementItem__delete {
  margin-top: 6px;
  padding: 6px 25px;
  border: 1px solid #B1B2BD;
  border-radius: 31px;
  color: #B1B2BD;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
