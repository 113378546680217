$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '~bootstrap/dist/css/bootstrap-reboot';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import './animations';
@import './ProximaNovaA.scss';
@import './ProximaNovaT.scss';
@import './ProximaNovaS.scss';
@import '../../variables';

body {
  margin: 0;
  font-family: 'ProximaNovaA', sans-serif;
  font-size: $font-sm;
}

.body--modal-visible {
  overflow: hidden;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: $font-xl;
}

h2 {
  font-size: $font-lg;
}

h3 {
  font-size: $font-md;
}

h4 {
  font-size: $font-sm;
}
