@import '../../variables';

.StepBreadcrumbs {
  display: inline-flex;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #E6E6E6
}
.StepBreadcrumb__item {
  padding-right: 10px;
  font-weight: 400;
  color: $grey;

  &:not(:last-child):after {
    padding-left: 5px;
    content: '/';
  }

  &.active {
    font-weight: 600;
    color: $dark_blue;
  }
}
