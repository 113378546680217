@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 200;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Thin.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Thin.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Thin.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 300;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Light.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Light.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Light.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 400;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Regular.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Regular.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Regular.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 500;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Semibold.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Semibold.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Semibold.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 700;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Bold.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Bold.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Bold.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 800;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Extrabld.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Extrabld.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Extrabld.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Extrabld.ttf') format('truetype');
}
@font-face {
  font-family: 'ProximaNovaA';
  font-weight: 900;
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Black.eot');
  src: url('./fonts/ProximaNovaA/ProximaNovaA-Black.eot?#iefix') format('embedded-opentype'),url('./fonts/ProximaNovaA/ProximaNovaA-Black.woff') format('woff'),url('./fonts/ProximaNovaA/ProximaNovaA-Black.ttf') format('truetype');
}
