@import '../../variables';
@import '../../components/Sidebar/style';

.Organizations {
  width: 100%;
  height: 100vh;
  background: $dark_blue;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $grey;

  .content {
    background: $blue;
    border-radius: 30px;
    box-shadow: 0 30px 130px 0 rgba(0,0,0,0.5);
    width: 470px;
    padding: 90px 50px 150px 50px;
    position: relative;
    min-height: 670.72px;

    h1 {
      color: $grey;
      font-weight: 400;
      font-size: 26px;
      margin: 0 0 40px 0;
    }

    span {
      font-weight: 700;
    }
    
    .strong {
      color: #fff;
    }

    a {
      color: $grey;
      display: block;
      border-bottom: 1px solid $grey;
      padding: 15px 0;
      transition: color .5s ease;
      font-weight: 300;
      font-size: 18px;

      &:hover {
        color: #fff;
      }
    }
  }
}
