.Modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(15,15,30, .4);
  z-index: 99999;
  left: 0;
  top: 0;
  overflow-y: auto;
}

.Modal__scroll-container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.Modal__inner {
  flex: 0 0 auto;
  position: relative;
  margin: 50px auto;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.09), 0 70px 130px 0 rgba(0,0,0,0.15);
  border-radius: 30px;
  padding: 100px 45px;
  min-width: 900px;
  max-width: calc(100% - 50px);

  > h4 {
    color: #0F0F1E;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    margin: 0 0 50px 0;
  }

  .button--save {
    margin: 60px auto 0 !important;
  }
}
