@import '../../variables';

.InlineContent {
  display: flex;
  justify-content: space-between;

  &:after {
    display: block;
    content: '';
    clear: both;
  }
}
