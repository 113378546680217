@import '../../variables';

.LocaleSelector {
  ul {
    padding: 12px 0;
    border-bottom: 1px solid #6F7080;
    border-top: 1px solid #6F7080;
    margin: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .expanded {
    ul {
      margin: 0 0 10px 88px;
      align-items: flex-start;
      width: 80px;
    }
  }

  li {
    cursor: pointer;
    padding-bottom: 2.5px;
    padding-top: 2.5px;
    color: #6F7080;

    &:hover {
      color: #fff;
    }
  }

  .expanded_img {
    opacity: 0;
  }

  .current {
    color: $yellow;
  }

  .active {
    span {
      color: #fff;
    }

    .expanded_img {
      opacity: 1;
      transition: .8s;
    }

    .collapsed_img {
      opacity: 0;
    }
  }
}
