@import '../../variables';

.FieldSubHeader {
  font-weight: 400;
  font-size: $font-md;
  margin: 1.9em 0 1.24em 0;

  > em {
    font-style: normal;
    color: $grey;
  }
}
