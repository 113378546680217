@import '../../variables';

.FieldContainer:not(:first-child) {
  margin-top: 100px;
}

.FieldContainer > *:not(:first-child) {
  margin-top: 20px;
}

.ListItem__update-all {
  background: transparent;
  padding: 15px 20px 12px 20px;
  border: 1px solid #DDE0E3;
  height: 54px;
  color: #8492A1;
  font-size: $font-xs;
  font-weight: 400;
  cursor: pointer;
  width: auto;
  display: inline-block;

  &:hover {
    background: $light-grey;
  }
}

.LegalBasis__general {
  margin-bottom: 15px;
}

.LegalBasis__subheader {
  font-size: $font-xs;
  margin-bottom: 5px;
  padding-left: 15px;
}
