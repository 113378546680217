@import '../../variables';

.DataMappingDepartmentWrapper {
  margin-bottom: 70px;

  h2 {
    margin-bottom: 30px;
  }
}

.DataMappingDepartmentWrapper__box {
  border-bottom: 1px solid #E6E6E6;
}

.DataMappingDepartmentWrapper__categories {
  h4 {
    height: 70px;
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 10px;
    cursor: pointer;
    border-top: 1px solid #E6E6E6;
    background: #F2F3F7;

    span {
      margin-right: 10px;
    }
  }

  td:first-child {
    padding-left: 29px;
  }

  th {
    display: none;
  }

  .TableWrapper {
    margin-bottom: 0;
    display: none;

    &.active {
      display: block;
    }

    tr:last-child {
      border-bottom: none;
    }
  }
}
