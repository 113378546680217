@import '../../variables';

.FieldHeader {
  font-weight: 700;
  font-size: $font-xl;
  color: #0F0F1E;
  line-height: 37px;
  margin-bottom: 20px;
  max-width: 800px;

  > em {
    font-style: normal;
    color: $grey;
  }
}
