.HeaderTitle {
  display: flex;

  font-size: 14px;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

}
.HeaderTitle__prefix {
  display: inline-flex;
  align-items: center;
  
  span, i {
    color: #8492A1;
  }
  i {
    padding: 0 8px;
  }
}
