@import '../../variables';

.ArchiveTitle {
  font-weight: 700;
  font-size: $font-xxl;
  color: #0F0F1E;
  line-height: 37px;
  margin-right: auto;

  .RiskWarning {
    color: $pink;
    font-size: $font-xs;
    margin-left: 12px;
    cursor: pointer;
  }
}
