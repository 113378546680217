@import '../../variables';

.UsersNew {
  
  .Modal {
    background: #F2F3F7;
  }

  .form__input-wrapper  {
    margin-bottom: 20px;

    input {
      margin-bottom: 0;
    }

    span {
      font-size: 12px;
      color: $pink;
      font-weight: 400;
    }
  }
}
