@import '../../variables';

.ThinButton {
  height: 32px;
  border-radius: 24px;
  background-color: $yellow;
  font-size: 14px;
  outline: 0;
  min-width: 140px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  margin-left: 16px;
  margin-top: -3px;
  padding: 0 20px;

  img {
    margin-left: 7px;
  }
}
