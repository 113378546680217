@import '../../variables';

.LogInField {
  position: relative;
  width: 100%;
  display: block;
  margin-top: 55px;

  > input {
    &:focus::-webkit-input-placeholder { opacity: 0 }
    &:focus:-moz-placeholder { opacity: 0 } /* FF 4-18 */
    &:focus::-moz-placeholder { opacity: 0 } /* FF 19+ */
    &:focus:-ms-input-placeholder { opacity: 0 } /* IE 10+ */

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #6F7080;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #6F7080;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #6F7080;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #6F7080;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }

    border: none;
    border-bottom: 1px solid #8492A1;
    font-size: 18px;
    outline: 0;
    background: none;
    display: block;
    transition: height .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    resize: none;
    padding: 10px 0;
    height: 54px;
    color: #fff;
    width: 100%;
    transition: border-color .3s ease;
  }

  > input:active,
  > input:focus {
    border-color: $yellow;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $blue inset;
    -webkit-text-fill-color: #fff;
    font-size: 18px;
  }
  input:-webkit-autofill::first-line {
    font-size: 18px;
    font-family: 'ProximaNovaA', sans-serif;
  }
}

.LogInField__label {
  margin-bottom: .5rem;
  color: #6F7080;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  left: 0;
  bottom: 10px;
  opacity: 0;
  transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  pointer-events: none;
}


.LogInField > input:focus + .LogInField__label,
.LogInField > input:active + .LogInField__label {
  opacity: 1;
  transform: translateY(-28px);
}
