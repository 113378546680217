.TabsWrapper {

  .tabs__wrapper {
    display: flex;
    margin-bottom: 60px;
  }
  .tabs__box {
    height: 55px;
    border: 1px solid #DDE0E3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tab {
    height: 37px;
    width: 148px;
    border-radius: 5px;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 7.5px;

    > p {
      margin: 0;
      font-weight: 400;
      color: #8492A1;
      padding: 5px;
      text-align: center;
      line-height: 1;
    }

    &.active{
      background-color: #ecf0f1;
      border: 1px solid #DDE0E3;

      > p {
        opacity: 1;
        color: black;
      }
    }
  }
  &.no-margin-bottom {
    .tabs__wrapper {
      margin-bottom: 0;
    }
  }

}
