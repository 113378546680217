@import '../../variables';

.Settings > .ContentSection {
  h3 {
    margin-bottom: 40px;
    font-size: 22px;
    font-weight: 700;
    color: #0F0F1E;
  }

  .form__input-wrapper {
    position: relative;
    padding-bottom: 40px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #0F0F1E;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #0F0F1E;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #0F0F1E;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #0F0F1E;
      font-weight: 300;
      transition: all .3s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }

    span {
      font-size: 12px;
      color: $pink;
      font-weight: 400;
    }

    input:focus::-webkit-input-placeholder { opacity: 0 }
    input:focus:-moz-placeholder { opacity: 0 } /* FF 4-18 */
    input:focus::-moz-placeholder { opacity: 0 } /* FF 19+ */
    input:focus:-ms-input-placeholder { opacity: 0 } /* IE 10+ */

    input {
      border: none;
      border-bottom: 1px solid #0F0F1E;
      font-size: 16px;
      font-weight: 400;
      outline: 0;
      background: none;
      display: block;
      transition: height .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
      resize: none;
      padding: 10px 0;
      height: 44px;
      color: #000;
      width: 100%;
      border-bottom-color: #E6E9ED;
      transition: border-color .3s ease;
    }

    input:active, input:focus {
      border-color: $yellow;
    }

    label {
      color: #6F7080;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
      pointer-events: none;
      margin-bottom: 0;
    }

    input:focus + label, input:active + label {
      opacity: 1;
      transform: translateY(-28px);
    }

    .reset-password {
      position: absolute;
      top: 25%;
      right: 0;
      color: $azure;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .button--save {
    border-radius: 31px;
    background-color: $yellow;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
    font-size: 12px;
    padding: 15.5px 51px;
    outline: 0;
    margin: 25px 0 50px 0;
    min-width: 156px;
    letter-spacing: 1px;
    display: block;
    &:disabled {
      background-color: #F2F3F7;
      color: #D9DADE;
    }
  }
}
