.ListHeader {
  width: 100%;
  display: block;
  margin: 0 0 20px 0;
  max-width: 550px;

  h2 {
    font-weight: 700;
    font-size: 22px;
    color: #0F0F1E;
    line-height: 27px;
    display: inline-block;
    margin: 0;
  }
}
