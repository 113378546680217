@import '../../variables';

.FormSelect {
  display: inline-block;
  position: relative;
  width: calc(50% - 15px);

  > i {
    position: absolute;
    right: 15px;
    top: 18.5px;
    font-size: $font-md;
    pointer-events: none;
  }

  > select {
    width: 100%;
    -webkit-appearance: listbox;
    appearance: listbox;
    outline: 0;
    border: 1px solid #E6E9ED;
    padding: 15px;
    font-size: $font-md;
    line-height: 22px;
    font-weight: 400;
    background: none;
    height: 54px;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    &::-ms-expand {
      display: none;
    }
  }
}

.FormSelect--fill {
  width: 100%;

  > select {
    width: 100%;
  }
}
