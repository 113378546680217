@import '../../variables';

.ColumnHeader {
  font-weight: 700;
  font-size: $font-xl;
  color: #0F0F1E;
  line-height: 37px;
  margin-bottom: 35px;

  > em {
    font-style: normal;
    color: $grey;
  }
}
