// SASS Media Queries ---------------------------- //
@mixin screen($res-min, $res-max, $orientation: false) {
  @if $orientation {
    @media screen and (min-width: $res-min) and (max-width: $res-max)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
  @else {
    @media screen and (min-width: $res-min) and (max-width: $res-max) {
      @content;
    }
  }
}

@mixin max-screen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}

// Colors ---------------------------- //

$yellow: #F8C533;
$blue: #151526;
$dark_blue: #0F0F1E;
$grey: #8492A1;
$azure: #107984;
$pink: #EF2954;
$light-grey: #ecf0f1;
$light-blue: #007bff;
$green: #1ea076;


// Font-sizes

$font-xxl: 1.875em; // ~30px
$font-xl: 1.5em; // ~24px
$font-lg: 1.375em; // ~22px
$font-md: 1.125em; // ~18px
$font-sm: 1em; // ~16px
$font-xs: .875em; // ~14px
$font-xxs: .65em; // ~12px
