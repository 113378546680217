.HorizontalAlign {
  display: flex;

  &--left {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: right;
  }
}
