@import '../../variables';

.RadioField {
  width: calc(25% - 15px);
  display: inline-flex;
  padding: 25px 18px;
  border: 1px solid #DDE0E3;
  margin-right: 15px;
  height: 60px;
  align-items: center;
  margin-left: -4px;
  cursor: pointer;

  > input[type="radio"] {
    visibility: hidden;
    height: 0;
    float: left;
  }

  &:hover {
    background: $light-grey;
  }
}

.RadioField--inline {
  display: inline-flex;
}

.RadioField__radio {
  background: none;
  border: 1px solid $grey;
  width: 1em;
  height: 1em;
  min-width: 1em;
  display: inline-block;
  float: left;
  border-radius: 5px;
  margin: 0 15px 0 0;
  position: relative;

  i {
    font-size: $font-xs;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    opacity: 0;
  }
}

.RadioField__description {
  font-weight: 500;
  font-size: $font-xs;
  line-height: 18px;
  color: #8492A1;
  vertical-align: top;
  margin-top: 2px;
}


.RadioField--checked {
  background: $light-grey;

  > .RadioField__radio {
    background: $yellow;
    border-color: $yellow;

    i {
      opacity: 1;
    }
  }

  > .RadioField__description {
    color: black;
  }
}
