.Departments {

  td {
    width: 33%;
  }
  
  tr:first-child {

    .OptionsPanel {
      display: none;
    }
  }
}
