@import '../../variables';

.TableWrapper {
  .wrapper {
    margin-bottom: 70px;
    margin-top: 70px;
  }

  h2 {
    margin-bottom: 30px;
  }

  table {
    width: 100%;

    tr {
      border-top: 1px solid #e6e6e6;
      height: 70px;
      min-height: 70px;
    }

    tr:last-child {
      border-bottom: 1px solid #e6e6e6;
    }

    td {
      margin: 0 15px;
      font-weight: 500;
      &.red {
        color: $pink;
      }
      &.green {
        color: #059a05;
      }
    }

    th {
      text-transform: uppercase;
      font-weight: 400;
    }

    tr th:last-child,
    td:last-child {
      width: 4%;
    }

    .column-count-2 {
      tr th,
      tr td {
        width: 96%;
      }
    }

    .column-count-3 {
      tr th,
      tr td {
        width: 48%;
      }
    }

    .column-count-4 {
      tr th,
      tr td {
        width: 32%;
      }
    }

    .column-count-5 {
      tr th,
      tr td {
        width: 24%;
      }
    }

    .column-count-6 {
      tr th,
      tr td {
        width: 19.2%;
      }
    }

    .sortable {
      cursor: pointer;
      i {
        padding-left: 5px;
      }
    }
  }

  .RiskWarning {
    color: $pink;
    font-size: $font-sm;
    margin-left: 5px;
    cursor: pointer;
  }
}
