@import '../../variables';

.ModalSaveButton {
  color: #0F0F1E;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  background-color: $yellow;
  padding: 15px 45px;
  border-radius: 31px;
  border: none;
  display: block;
  margin: 60px auto 0 auto;
}
