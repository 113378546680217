.ModalCloseButton {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  span {
    width: 16px;
    height: 2px;
    background: #0F0F1E;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    top: 50%;
    left: 50%;
  }

  span:last-child {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
